import { graphql } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-grid-system"
import Breadcrumbs from "../components/breadcrumbs"
import ProductsList from "../components/productsList"
import SEO from "../components/seo"
import Select from "../components/UI/select"
import { H1 } from "../components/UI/typography"

export default ({ data, pageContext }) => {
  const {
    allWcProducts: { edges: products },
  } = data
  const {
    breadcrumb: { crumbs },
    categoryName,
    categoryDescription,
  } = pageContext
  const crumbLabel =
    categoryName.length > 10
      ? categoryName.substring(0, 10).concat("...")
      : categoryName

  const sortingItems = [
    {
      value: 0,
      label: "По умолчанию",
    },
    {
      value: 1,
      label: "Цена: по возрастанию",
    },
    {
      value: 2,
      label: "Цена: по убыванию",
    },
  ]
  const [currentSorting, setCurrentSorting] = useState(0)
  const sortedProducts = [].concat(products).sort((a, b) => {
    const aPrice = parseInt(a.node.price)
    const bPrice = parseInt(b.node.price)
    const aOrder = a.node.menu_order
    const bOrder = b.node.menu_order
    switch (parseInt(currentSorting)) {
      case 0:
      default:
        return aOrder - bOrder
      case 1:
        return aPrice - bPrice
      case 2:
        return bPrice - aPrice
    }
  })
  return (
    <Container>
      <SEO title={categoryName} />
      <Breadcrumbs crumbs={crumbs} label={crumbLabel} />
      <H1>{categoryName}</H1>
      <Row css={{ marginBottom: 20 }} justify="center">
        <Col>
          <div css={{ display: "flex", justifyContent: "flex-end" }}>
            <Select items={sortingItems} _onChange={setCurrentSorting} />
          </div>
        </Col>
      </Row>
      {sortedProducts ? (
        <ProductsList
          products={sortedProducts.map(edge => {
            const { node: product } = edge
            return {
              key: product.id,
              id: product.id,
              wordpress_id: product.wordpress_id,
              name: product.name,
              price: product.price,
              stock_status: product.stock_status,
              image:
                product.images.length > 0
                  ? {
                      fluid: product.images[0].localFile.childImageSharp.fluid,
                    }
                  : null,
              attributes: product.attributes,
              variations:
                product.product_variations.length > 0
                  ? product.product_variations
                  : null,
            }
          })}
        />
      ) : (
        <p>В этой категории пока нет товаров.</p>
      )}
      <p>{categoryDescription}</p>
    </Container>
  )
}

export const query = graphql`
  query Products($slug: String) {
    allWcProducts(
      sort: { fields: menu_order, order: ASC }
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          name
          price
          id
          wordpress_id
          menu_order
          images {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 250, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          attributes {
            id
            name
            options
            visible
          }
          product_variations {
            id
            price
            attributes {
              id
              name
              option
            }
            menu_order
          }
          stock_status
        }
      }
    }
  }
`
